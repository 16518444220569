<template>
    <div>
      <button v-if="!loggedIn" @click="login">マイナポータルに接続する</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loggedIn: false,
        user: null,
      };
    },
    methods: {
      login() {
        // const baseUrl = 'https://fujikawa-api.jp.ngrok.io/';
        const redirectBaseUrl = 'https://dev.myna-portal.digitalpharmacy.jp/auth_code/'
        // const baseUrl = 'https://liff.line.me/1655376072-JyKNQWgZ'

        const oauthBaseUrl = 'https://app-test.api.myna.go.jp/online-authorization-web/oauth/authorize';

        const clientId = 'SP00000039_AA0001'
        const state = Math.random().toString(36).substring(2, 10)
        const redirectParam = `&state=${state}&user_type=0&execution_mode=1&login_type=1`
        const oauthParam = `?response_type=code&client_id=${clientId}&openExternalBrowser=1`
        const oauthUrl = `${oauthBaseUrl}${oauthParam}&redirect_uri=${redirectBaseUrl}${redirectParam}`

        window.location.href = oauthUrl;
      }
    },
  };
  </script>
  